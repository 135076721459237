.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  padding-top: 150px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.overlayWhite {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255, 0.7);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-contentWhite {
  padding-top: 150px;
  display: flex;
  justify-content: center;
}

.popUp {
  padding-top: 180px;
}

.ModalBody {
  max-height: calc(100vh - 210px);
}

.paymentProcessing {
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 25px;
  color: white;
}
