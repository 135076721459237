.elementsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;
}

.PaymentHeaders {
  color: #295c88;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

.orderInfoCard {
  display: flex;
  justify-content: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
  margin-top: 5px;
  margin-top: 5px;
  width: 70%;
  border: 1px solid grey;
  border-radius: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
}

.cardForm {
  display: flex;
  justify-content: center;
}

.brand {
  float: right;
}

.data {
  text-align: left;
}

.PaymentRequestButton {
  width: 100% !important;
}

.wrapperCustomerInfo {
  margin-top: 15px;
}

.wrapperCustomerInfo input {
  outline-width: 0;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
}

.submitButton {
  outline-width: 0;
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: white;
  font-weight: bold;
  background-color: #295c88;
  background-image: none;
  border: 1px solid #295c88;
  border-radius: 4px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
}

.submitButton:hover {
  cursor: pointer;
}

.questionMarkIcon {
  position: absolute;
  top: 6px;
  right: 23px;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
}

@media screen and (min-width: 414px) {
  .securityfield {
    float: left;
  }
}

@media (min-width: 380px) and (max-width: 767px) {
  .securityfield {
    width: 33%;
    float: right;
  }
  .wrapperInnerExpiryDate .expirationfield {
    padding: 0px;
    width: 67%;
    float: left;
  }
}

@media screen and (max-width: 380px) {
  .securityfield {
    width: 49%;
    float: right;
    padding: 0px;
  }
  .wrapperInnerExpiryDate .expirationfield {
    padding: 0px;
    width: 49%;
    float: left;
  }
  .wrapperInnerExpiryDate .securityfield label {
    float: right;
  }
}

.wrapperExpiryDate select {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapperExpiryDate select {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
}

.wrapperInnerExpiryDate select {
  display: inline;
}

.wrapperInnerExpiryDate .col-xs-4,
.wrapperInnerExpiryDate .col-sm-4,
.wrapperInnerExpiryDate .col-xs-6,
.wrapperInnerExpiryDate.col-sm-6 {
  padding-right: 0px;
  padding-left: 0px;
}

.expiry_field {
  padding-right: 10px !important;
}

.securityfield {
  justify-content: flex-end;
}

.cardElement {
  display: block;
  width: 100%;
  height: 34px;
  padding: 10px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
}

.expiryElement {
  display: block;
  width: 100%;
  height: 34px;
  padding: 10px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
}

.cvvElement {
  display: block;
  width: 100%;
  height: 34px;
  padding: 10px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
}

.cardInfo {
  margin-bottom: 40px;
  margin-top: 5px;
  border: 1px solid grey;
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
}

.orderInfo {
  width: 40%;
  margin: 40px;
  border: 1px solid grey;
  border-radius: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.orderCardInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid grey;
  border-radius: 20px;
  background-color: white;
  padding: 15px;
  margin-top: 7px;
  margin-bottom: 10px;
}

.cardPayment-container {
  display: flex;
  justify-content: center;
}
