.tabs {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.planSummary {
  display: flex;
  justify-content: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.numberOfTests {
  display: flex;
  width: 60%;
  height: 100%;
  justify-content: center;
  align-items: center;
  float: left;
  font-size: 22px;
  font-family: 'Open Sans', sans-serif;
}

.increment {
  display: flex;
  border-left: 1px solid #295c88;
  justify-content: center;
  align-items: center;
  float: right;
  height: 100%;
  width: 20%;
  font-size: 25px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Open Sans', sans-serif;
}

.increment:hover {
  background-color: #295c88;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: white;
}

.decrement:hover {
  background-color: #295c88;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: white;
}

.decrement {
  display: flex;
  border-right: 1px solid #295c88;
  align-items: center;
  justify-content: center;
  float: left;
  height: 100%;
  width: 20%;
  font-size: 25px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.editablePlan {
  margin-top: 4px;
  margin-left: 12px;
  margin-right: 12px;
  height: 50px;
  border: 0.5px solid #295c88;
  border-radius: 6px;
}

.submit {
  display: flex;
  justify-content: center;
}

.buyButton {
  background-color: #295c88;
  border: 1px solid #295c88;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 130px;
  height: 45px;
  color: white;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: bold;
}

.buyButton:hover {
  cursor: pointer;
}

.subscriptionPlansTitle {
  justify-content: center;
  display: flex;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 30px;
  margin-bottom: 10px;
}

.nav-pills .nav-link {
  border-radius: 0px;
}

.nav-link {
  border-bottom: 3px solid #ffffff;
  border-radius: 0px;
  font-weight: bold;
  cursor: pointer;
  color: #ffffff;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
}

.nav-item .open {
  border-bottom: 3px solid #295c88;
  color: #295c88;
}

a {
  font-size: 18px;
  color: white;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
}

sup {
  font-size: 40px;
  font-family: 'Open Sans', sans-serif;
}

.content ul {
  list-style: none;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  color: black;
  padding: 0px;
  margin: 0px;
}

.content ul li {
  padding-top: 10px;
  margin: 0px;
  text-align: center;
  height: 52px;
}

#container {
  width: 100%;
  margin: auto;
  text-align: center;
}

.whole {
  display: inline-block;
  width: 202px;
  border: 1px solid rgba(41, 92, 136, 0.253);
  margin: 20px;
  border-radius: 6px;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.wholeDefault {
  display: inline-block;
  width: 202px;
  border: 1px solid #295c88;
  margin-bottom: 20px;
  border-radius: 6px;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.wholeRecurring {
  display: inline-block;
  width: 210px;
  border: 1px solid rgba(41, 92, 136, 0.253);
  margin: 20px;
  border-radius: 6px;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.wholeDefaultRecuring {
  display: inline-block;
  width: 210px;
  border: 1px solid #295c88;
  margin: 20px;
  border-radius: 6px;
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.whole:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.wholeDefault:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.wholeRecurring:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.wholeDefaultRecuring:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.typeRecuring {
  background-color: rgba(41, 92, 136, 0.253);
  height: 50px;
}

.typeDefaultRecuring {
  border-radius: 5px 5px 0px 0px;
  background-color: #295c88;
  height: 50px;
}

.typeRecuring p {
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  color: black;
  text-align: center;
  padding-top: 10px;
}

.typeDefaultRecuring p {
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  color: white;
  text-align: center;
  padding-top: 10px;
}

.type {
  width: 200px;
  background-color: rgba(41, 92, 136, 0.253);
  height: 50px;
}

.typeDefault {
  width: 200px;
  border-radius: 5px 5px 0px 0px;
  background-color: #295c88;
  height: 50px;
}

.type p {
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  color: black;
  text-align: center;
  padding-top: 10px;
}

.typeDefault p {
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  color: white;
  text-align: center;
  padding-top: 10px;
}

.plan {
  background-color: white;
  border-radius: 0px 0px 5px 5px;
  font-family: 'Open Sans', sans-serif;
  font-style: condensed;
  font-size: 30px;
  color: black;
  text-align: center;
}

.planCurrency {
  font-size: 12px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

.planHeader {
  border-bottom: 1px solid #494a5a;
  height: 120px;
}

.planHeaderOnDiscount {
  border-bottom: 1px solid #494a5a;
  height: 145px;
}

.planHeaderRecurring {
  border-bottom: 1px solid #494a5a;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding-bottom: 15px;
}

.discountText {
  font-size: 12px;
  color: #295c88;
}

.editablePlanRecurring {
  margin-bottom: 0px;
  margin-left: 12px;
  margin-right: 12px;
  border: 0.5px solid #295c88;
  border-radius: 6px;
  height: 48px;
}

.planHeader span {
  font-size: 22px;
  font-family: 'Open Sans', sans-serif;
}

.month {
  font-size: 14px;
  color: 'black';
  padding-top: 10px;
  margin: -10px;
  font-family: 'Open Sans', sans-serif;
}

.cart {
  color: white;
  position: relative;
  top: 15px;
  height: 80%;
}

.bottom {
  font-size: 22px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
}

.discountedPrice {
  text-decoration: line-through;
  color: #285c87;
  font-size: 18px;
}
